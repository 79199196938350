import React, { useContext, useState, useEffect } from "react"
import Page from "../components/page"
import { LangContext } from "../hooks/lang.js"
import main from "../../static/main.svg"
import oreille from "../../static/oreille.svg"
import shuffle from "../../static/shuffle.svg"
import etendre from "../../static/etendre.svg"
import flammeRose from "../../static/flammeRose.svg"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Apropos = () => {
  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null
  let french = langContext && langContext.lang && langContext.lang === "fr-CA"

  let [ dynamicData, setDynamicData ] = useState(null)

  useEffect(() => {
    window.contentfulClient
      .getEntries({ content_type: "pagePropos", locale: lang })
      .then(p => {
        let dd = p.items[ 0 ].fields
        if (dd.teamMembers) {
          dd.teamMembers = dd.teamMembers.map(m => ({
            name: m.fields.name || null,
            description: m.fields.description || null,
            job: m.fields.job || null,
          }))
        }
        if (dd.projetPhare && dd.projetPhare.fields) {
          dd.projetPhare = {
            ...dd.projetPhare.fields,
            imagesPreview:
              (dd.projetPhare.fields.imagesPreview &&
                dd.projetPhare.fields.imagesPreview.map(
                  ppp => ppp.fields.file.url
                )) ||
              null,
            videosPreview:
              (dd.projetPhare.fields.videosPreview &&
                dd.projetPhare.fields.videosPreview.map(
                  ppp => ppp.fields.file.url
                )) ||
              null,
            id: dd.projetPhare.sys.id,
          }
        } else {
          dd.projetPhare = null;
        }
        setDynamicData(dd)
      })
  }, [ lang ])

  if (dynamicData) {
    let { projetPhare } = dynamicData;
    let { teamMembers } = dynamicData;
    console.log(teamMembers)
    return (
      <div className="apropos">
        <section className="section1">
          <h1>{french ? "À propos" : "About"}</h1>
        </section>
        <section className="section2">
          <h2>{dynamicData.header}</h2>
        </section>
        <section className="section3">
          <div className="leftpane">
            <p>{dynamicData.texteValeurs}</p>
          </div>
          <div className="rightpane">
            <div className="value">
              <img src={oreille} alt="" />
              <span>{french ? "Écoute" : "Care"}</span>
            </div>
            <div className="value">
              <img src={main} alt="" />
              <span>{french ? "Collaboration" : "Collaboration"}</span>
            </div>
            <div className="value">
              <img src={shuffle} alt="" />
              <span>{french ? "Respect" : "Respect"}</span>
            </div>
            <div className="value">
              <img src={etendre} alt="" />
              <span>{french ? "Transparence" : "Transparency"}</span>
            </div>
          </div>
        </section>
        <section className="section2">
          <h2>{dynamicData.headerDeux}</h2>
        </section>

        <div className="section4">
          <h1>{dynamicData.headerTrois}</h1>
          <div className="section4__columns">
            <div className="section4__column">
              <h2>{dynamicData.titreValeurUn} </h2>
              <p>
                {dynamicData.sousTitreValeurUn}
                <br />
                {dynamicData.valeurUn}
              </p>
            </div>
            <div className="section4__column">
              <h2>{dynamicData.titreValeurDeux}</h2>
              <p>
                {dynamicData.sousTitreValeurDeux}
                <br />
                {dynamicData.valeurDeux}
              </p>
            </div>
            <div className="section4__column">
              <h2>{dynamicData.titreValeurTrois}</h2>
              <p>
                {dynamicData.sousTitreValeurTrois}
                <br />
                {dynamicData.valeurTrois}
              </p>
            </div>
          </div>

          <p className="section4__footer">{dynamicData.headerQuatre}</p>
        </div>
        <section className="section5">
          <h1>{french ? "ÉQUIPE" : "TEAM"}</h1>
          <div className="section5__columns">
            {teamMembers.map(m => (
              <div className="section5__column">
                <h2>{m.name}</h2>
                <h3>{m.job}</h3>
                <p>{documentToReactComponents(m.description)}</p>
              </div>
            ))}
          </div>

          <img className="flamme" src={flammeRose} alt="Pink Flame graphic" />
        </section>

        {dynamicData.headerCinq && dynamicData.texteCinq && (
          <section className="section6">
            <h1>{dynamicData.headerCinq}</h1>
            <p>{dynamicData.texteCinq}</p>
          </section>
        )}

        {projetPhare && (
          <section className="projets">
            <a href={`/projet/${projetPhare.id}`}>
              <div className="projet">
                <div className="projet__info">
                  <h2>
                    {projetPhare.titre}
                  </h2>
                  <p>
                    {projetPhare.descriptionPreview}</p>
                </div>
                {projetPhare.imagePhare.fields.file.url && (
                  <img
                    src={projetPhare.imagePhare.fields.file.url}
                    className="projet__img"
                    alt="projet sample work"
                  ></img>
                )}
              </div>
            </a>
          </section>
        )}
      </div>
    )
  } else {
    return <div>{french ? "Chargement" : "Loading"}</div>
  }
}

const AproposWithPage = props => (
  <Page>
    <Apropos {...props} />
  </Page>
)
export default AproposWithPage
